import React from 'react';
import ProSerLayout from '../../ProSerLayout/ProSerLayout';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProductPage = props => {
	const [t, i18n] = useTranslation();
	let page = props.match.params.productName;
	const data = t('productsService.' + page, { returnObjects: true });
	console.log(data);
	return (
		<ProSerLayout data={data}>
			<Link to='/contact'>{t('global.price')}</Link>
		</ProSerLayout>
	);
};

export default ProductPage;
