import React from 'react';
import classes from './ProductsLayout.module.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ProductsLayout = () => {
	const [t, i18n] = useTranslation();
	return (
		<>
			<article className={classes.ProductsHeader}>
				<h1>{t('nav.products')}</h1>
				<p>{t('home.products.description')}</p>
			</article>
			<article className={classes.products}>
				<figure>
					<img src={require('../../_assets/imgs/Primex.jpeg')} alt='' />
					<figcaption>
						<h3> {t('products.product1.name')}</h3>
						<div className={classes.bottomProducts}>
							<NavLink className={classes.ServiceBtn} to='/products/premixes'>
								{t('global.more')}
							</NavLink>
						</div>
					</figcaption>
				</figure>

				<figure>
					<img src={require('../../_assets/imgs/Feedadditives2.jpeg')} alt='' />
					<figcaption>
						<h3> {t('products.product2.name')}</h3>
						<div className={classes.bottomProducts}>
							<NavLink
								className={classes.ServiceBtn}
								to='/products/feedAdditives'
							>
								{t('global.more')}
							</NavLink>
						</div>
					</figcaption>
				</figure>
				<figure>
					<img
						src={require('../../_assets/imgs/Animalshealthbio.jpeg')}
						alt=''
					/>
					<figcaption>
						<h3> {t('products.product3.name')}</h3>
						<div className={classes.bottomProducts}>
							<NavLink
								className={classes.ServiceBtn}
								to='/products/animalHealth'
							>
								{t('global.more')}
							</NavLink>
						</div>
					</figcaption>
				</figure>
			</article>
		</>
	);
};

export default ProductsLayout;
