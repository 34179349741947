import React from 'react';
import classes from './NotFound.module.scss';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
	const [t, i18n] = useTranslation();
	return (
		<main className={classes.notFound}>
			<h1>{t('global.page404')}</h1>
		</main>
	);
};

export default NotFound;
