import React from 'react';
import classes from './About.module.scss';
import { useTranslation, Trans } from 'react-i18next';

const About = props => {
	const [t, i18n] = useTranslation();
	return (
		<main className={classes.about}>
			<figure>
				<div className={classes.aboutImg}>
					<img src={require('../_assets/imgs/aboutus.jpg')} alt='' />
				</div>
				<figcaption>
					<h1>{t('about.title')}</h1>
					<ul>
						<li>
							<h3>{t('about.part1.title')}</h3>
							<p>{t('about.part1.text0')}</p>
						</li>
						<li>
							<h3>{t('about.part2.title')}</h3>
							<p>{t('about.part2.text0')}</p>
							{i18n.exists('about.part2.text1') && (
								<p>{t('about.part2.text1')}</p>
							)}
						</li>
						<li>
							<h3>{t('about.part3.title')}</h3>
							<p>{t('about.part3.text0')}</p>
						</li>
						<li>
							<h3>{t('about.part4.title')}</h3>
							<p>{t('about.part4.text0')}</p>
						</li>
					</ul>
				</figcaption>
			</figure>
		</main>
	);
};

export default About;
