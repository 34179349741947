import React from 'react';
import classes from './Footer.module.scss';
import { useTranslation } from 'react-i18next';

const Footer = props => {
	const [t, i18n] = useTranslation();
	return (
		<footer className={classes.footer}>
			<h1>{t('global.companyNameGmbh')}</h1>
			<a href={'mailto:' + t('global.mail')}>
				<small>{t('global.mail')}</small>
			</a>
			<p>
				<small>{t('global.address')}</small>
			</p>
			<ul className={classes.icons}>
				<li>
					<a
						href='https://www.facebook.com/DNSHtrading/'
						rel='noopener noreferrer'
						target='_blank'
					>
						<img
							src={require('../_assets/Icons/facebook.svg')}
							alt='facebook'
						/>
					</a>
				</li>
				<li>
					<a
						href='https://twitter.com/DnshGmbh'
						rel='noopener noreferrer'
						target='_blank'
					>
						<img src={require('../_assets/Icons/twit.svg')} alt='tiwiter' />
					</a>
				</li>
				<li>
					<a href='/'>
						<img
							src={require('../_assets/Icons/linkedin.svg')}
							alt='linkedin'
						/>
					</a>
				</li>
			</ul>
			<div>
				<small>{t('global.copyRight')}</small>
			</div>
		</footer>
	);
};

export default Footer;
