import React from 'react';
import classes from './MobNav.module.scss';
import BgTrans from '../../Modal/BgTrans/BgTrans';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class MobNav extends React.Component {
	state = {
		isShow: false
	};
	openHandler = () => {
		this.setState({ isShow: true });
	};
	closeHandler = () => {
		this.setState({ isShow: false });
	};
	changeLanguageHandler = lang => {
		this.props.i18n.changeLanguage(lang);
		localStorage.setItem('langauge', lang);
	};
	render() {
		let mobNav = [classes.mobNav];
		let bgblack = null;
		if (this.state.isShow) {
			mobNav.push(classes.open);
			bgblack = <BgTrans closeHandler={this.closeHandler} />;
		}

		const flagStyle = { width: '30px', marginLeft: '10px', cursor: 'pointer' };
		return (
			<>
				<button className={classes.hamburgerBtn} onClick={this.openHandler}>
					<img
						src={require('../../_assets/Icons/hamburgerBtn.svg')}
						alt='hamburger button'
					/>
				</button>
				<div style={{ overflow: 'auto' }} className={mobNav.join(' ')}>
					<button className={classes.closeBtn} onClick={this.closeHandler}>
						<img
							src={require('../../_assets/Icons/closeBtn.svg')}
							alt='close button'
						/>
					</button>
					<ul>
						<li>
							<NavLink to='/' onClick={this.closeHandler}>
								{this.props.t('nav.home')}
							</NavLink>
						</li>
						<li className={classes.dropMenu}>
							<NavLink to='/products' onClick={this.closeHandler}>
								{this.props.t('nav.products')}
							</NavLink>
							<ul>
								<li>
									<NavLink to='/products/premixes' onClick={this.closeHandler}>
										{this.props.t('products.product1.name')}
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/products/feedAdditives'
										onClick={this.closeHandler}
									>
										{this.props.t('products.product2.name')}
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/products/animalHealth'
										onClick={this.closeHandler}
									>
										{this.props.t('products.product3.name')}
									</NavLink>
								</li>
							</ul>
						</li>
						<li className={classes.dropMenu}>
							<NavLink to='/services' onClick={this.closeHandler}>
								{this.props.t('nav.service')}
							</NavLink>
							<ul>
								<li>
									<NavLink
										to='/services/feedSolutions'
										onClick={this.closeHandler}
									>
										{this.props.t('service.service1.name')}
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/services/feedManufactSolutions'
										onClick={this.closeHandler}
									>
										{this.props.t('service.service2.name')}
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/services/poulFarmSolutions'
										onClick={this.closeHandler}
									>
										{this.props.t('service.service3.name')}
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/services/renewGreenEnergy'
										onClick={this.closeHandler}
									>
										{this.props.t('service.service4.name')}
									</NavLink>
								</li>
							</ul>
						</li>
						<li>
							<NavLink to='/about' onClick={this.closeHandler}>
								{this.props.t('nav.about')}
							</NavLink>
						</li>
						<li>
							<NavLink to='/contact' onClick={this.closeHandler}>
								{this.props.t('nav.contact')}
							</NavLink>
						</li>
						<li style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<div style={flagStyle}>
								<img
									src={require('../../_assets/Icons/english.png')}
									alt='english'
									onClick={() => this.changeLanguageHandler('en')}
								/>
							</div>
							<div style={flagStyle}>
								<img
									src={require('../../_assets/Icons/germany.png')}
									alt='german'
									onClick={() => this.changeLanguageHandler('de')}
								/>
							</div>
							<div style={flagStyle}>
								<img
									src={require('../../_assets/Icons/arabic.png')}
									alt='arabic'
									onClick={() => this.changeLanguageHandler('ar')}
								/>
							</div>
						</li>
					</ul>
				</div>
				{bgblack}
			</>
		);
	}
}

export default withTranslation()(MobNav);
