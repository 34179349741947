import React from 'react';
import classes from './DesNav.module.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DesNav = props => {
	const [t, i18n] = useTranslation();

	return (
		<>
			<ul className={classes.desNav}>
				<li>
					<NavLink to='/'> {t('nav.home')}</NavLink>
				</li>
				<li className={classes.dropMenu}>
					<NavLink to='/products'>{t('nav.products')}</NavLink>
					<ul>
						<li>
							<NavLink to='/products/premixes'>
								{t('products.product1.name')}
							</NavLink>
						</li>
						<li>
							<NavLink to='/products/feedAdditives'>
								{t('products.product2.name')}
							</NavLink>
						</li>
						<li>
							<NavLink to='/products/animalHealth'>
								{t('products.product3.name')}
							</NavLink>
						</li>
					</ul>
				</li>
				<li className={classes.dropMenu}>
					<NavLink to='/services'>{t('nav.service')}</NavLink>
					<ul>
						<li>
							<NavLink to='/services/feedSolutions'>
								{t('service.service1.name')}
							</NavLink>
						</li>
						<li>
							<NavLink to='/services/feedManufactSolutions'>
								{t('service.service2.name')}
							</NavLink>
						</li>
						<li>
							<NavLink to='/services/poulFarmSolutions'>
								{t('service.service3.name')}
							</NavLink>
						</li>
						<li>
							<NavLink to='/services/renewGreenEnergy'>
								{t('service.service4.name')}
							</NavLink>
						</li>
					</ul>
				</li>
				<li>
					<NavLink to='/about'>{t('nav.about')}</NavLink>
				</li>
				<li>
					<NavLink to='/contact'>{t('nav.contact')}</NavLink>
				</li>
			</ul>
		</>
	);
};

export default DesNav;
