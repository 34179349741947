import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageDirection = props => {
	const [t, i18n] = useTranslation();
	useEffect(() => {
		if (localStorage.getItem('langauge')) {
			console.log('ss');

			i18n.changeLanguage(localStorage.getItem('langauge'));
		}
	}, []);
	return (
		<div style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}>
			{props.children}
		</div>
	);
};
export default LanguageDirection;
