import React from 'react';
import NavBar from './Components/NavBar/NavBar';
import Router from './Router/Router';
import Footer from './Components/Footer/Footer';
import ScrollToTop from './Container/ScrollToTop/ScrollToTop';
import LanguageDirection from './Container/LanguageDirection/LanguageDirection';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
	return (
		<ScrollToTop>
			<ToastContainer />
			<NavBar />
			<LanguageDirection>
				<Router />
			</LanguageDirection>
			<Footer />
		</ScrollToTop>
	);
};

export default App;
