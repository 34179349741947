import React from 'react';
import classes from './Contact.module.scss';
import FormContact from '../../Container/FormContact/FormContact';
import { useTranslation } from 'react-i18next';

const Contact = () => {
	const [t, i18n] = useTranslation();
	return (
		<main className={classes.contact}>
			<section className={classes.background}>
				<div className={classes.contactText}>
					<article>
						<h1>{t('contact.name')}</h1>
						<p>{t('contact.description')}</p>
						<p>
							<small>{t('global.address')}</small>
						</p>
						<a
							href={'mailto:' + t('global.mail')}
							target='_blank'
							rel='noopener noreferrer'
						>
							<small>{t('global.mail')}</small>
						</a>
						<p style={{ direction: 'ltr' }}>{t('global.number')}</p>
					</article>
					<article className={classes.contactForm}>
						<FormContact t={t} />
					</article>
				</div>
			</section>
			<div>
				<iframe
					title='Location'
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2512.8649043314795!2d13.319840915545484!3d50.96320195816683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a76052d560e9d9%3A0x2075d2af450ae603!2sMuldentalstra%C3%9Fe%2016%2C%2009603%20Gro%C3%9Fschirma!5e0!3m2!1sen!2sde!4v1598190177632!5m2!1sen!2sde"
					width='100%'
					height='400'
					frameBorder='0'
					style={{ border: 0 }}
					allowFullScreen
				></iframe>
			</div>
		</main>
	);
};

export default Contact;
