import React from 'react';
import classes from './Services.module.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Servers = props => {
	const [t, i18n] = useTranslation();
	return (
		<main className={classes.servicesContanier}>
			<section className={classes.services}>
				<div className={classes.servicesPageInfo}>
					<h1>{t('nav.service')}</h1>
					<p>{t('home.service.description')}</p>
				</div>
				<article className={classes.servicesCollection}>
					<figure>
						<div className={classes.serviceImg}>
							<img
								src={require('../_assets/Icons/feedSolutionsblue.svg')}
								alt='feed solution img'
							/>
						</div>
						<figcaption>
							<h3>{t('service.service1.name')}</h3>
							<p>{t('home.service.service1')}</p>
							<NavLink
								className={classes.greenBtn}
								to='/services/feedSolutions'
							>
								{t('global.more')}
							</NavLink>
						</figcaption>
					</figure>
					<figure>
						<div className={classes.serviceImg}>
							<img
								src={require('../_assets/Icons/FeedManufacturingSolutionblue.svg')}
								alt='feed solution img'
							/>
						</div>
						<figcaption>
							<h3>{t('service.service2.name')}</h3>
							<p>{t('home.service.service2')}</p>
							<NavLink
								className={classes.greenBtn}
								to='/services/feedManufactSolutions'
							>
								{t('global.more')}
							</NavLink>
						</figcaption>
					</figure>
					<figure>
						<div className={classes.serviceImg}>
							<img
								src={require('../_assets/Icons/PoultryFarmSolutionsblue.svg')}
								alt='feed solution img'
							/>
						</div>
						<figcaption>
							<h3>{t('service.service3.name')}</h3>
							<p>{t('home.service.service3')}</p>
							<NavLink
								className={classes.greenBtn}
								to='/services/poulFarmSolutions'
							>
								{t('global.more')}
							</NavLink>
						</figcaption>
					</figure>
					<figure>
						<div className={classes.serviceImg}>
							<img
								src={require('../_assets/Icons/RenewableGreenEnergyblue.svg')}
								alt='feed solution img'
							/>
						</div>
						<figcaption>
							<h3>{t('service.service4.name')}</h3>
							<p>{t('home.service.service4')}</p>
							<NavLink
								className={classes.greenBtn}
								to='/services/renewGreenEnergy'
							>
								{t('global.more')}
							</NavLink>
						</figcaption>
					</figure>
				</article>
			</section>
		</main>
	);
};

export default Servers;
