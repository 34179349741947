import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './Languages/en/TranslationEN.json';
import translationDE from './Languages/de/TranslationDE.json';
import translationAR from './Languages/ar/TranslationAR.json';

// the translations
const resources = {
	en: {
		translation: translationEN
	},
	de: {
		translation: translationDE
	},
	ar: {
		translation: translationAR
	}
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'de',
		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		}
	});
export default i18n;
