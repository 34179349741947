import React, { useState } from 'react';
import classes from './FormContact.module.scss';
import { withFormik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

const init = {
	userName: '',
	email: '',
	telephone: '',
	address: '',
	subject: '',
	message: ''
};
let recaptchaInstance;
const FormContact = ({ values, errors, touched, isValid, t }) => {
	const { reCAPTCHA, setReCAPTCHA } = useState(false);

	const verifycallback = response => {
		if (response) setReCAPTCHA(true);
	};
	return (
		<Form className={classes.formContact}>
			<Field
				name='userName'
				placeholder={t('contact.form.name')}
				className={errors.userName && touched.userName && classes.errorBox}
			/>
			<ErrorMessage
				className={classes.textError}
				component='div'
				name='userName'
			/>
			<Field
				name='email'
				placeholder={t('contact.form.email')}
				className={errors.email && touched.email && classes.errorBox}
			/>
			<ErrorMessage
				className={classes.textError}
				component='div'
				name='email'
			/>
			<Field
				name='telephone'
				placeholder={t('contact.form.telephone')}
				className={errors.telephone && touched.telephone && classes.errorBox}
			/>
			<ErrorMessage
				className={classes.textError}
				component='div'
				name='telephone'
			/>
			<Field
				name='address'
				placeholder={t('contact.form.address')}
				className={errors.address && touched.address && classes.errorBox}
			/>
			<ErrorMessage
				className={classes.textError}
				component='div'
				name='address'
			/>
			<Field
				name='subject'
				placeholder={t('contact.form.subject')}
				className={errors.subject && touched.subject && classes.errorBox}
			/>
			<ErrorMessage
				className={classes.textError}
				component='div'
				name='subject'
			/>
			<Field
				component='textarea'
				rows='4'
				name='message'
				placeholder={t('contact.form.message')}
				className={errors.message && touched.message && classes.errorBox}
			/>
			<ErrorMessage
				className={classes.textError}
				component='div'
				name='message'
			/>
			<ReCAPTCHA
				style={{ margin: '0 auto' }}
				sitekey='6Lceo7kUAAAAAHKXc1M7zJduotCyawBZGfth_4_S'
				render='explicit'
				ref={e => (recaptchaInstance = e)}
				verifycallback={verifycallback}
			/>
			<input type='submit' value='Senden' disabled={!reCAPTCHA && !isValid} />
		</Form>
	);
};
const FormFormik = withFormik({
	mapPropsToValues() {
		return init;
	},
	handleSubmit: (values, { props, resetForm }) => {
		axios
			.post('http://dnsh-trading.net/send.php', values)
			.then(res => {
				if (res.data.sent) {
					toast.success(props.t('responseMessage.success.sent'));
					resetForm(init);
				} else {
					toast.error(props.t('responseMessage.error.resProblem'));
				}
			})
			.catch(err => toast.error(props.t('responseMessage.error.server')));
		recaptchaInstance.reset();
	},
	validationSchema: ({ t }) => {
		return Yup.object().shape({
			userName: Yup.string()
				.min(2, t('responseMessage.error.yup.name.min'))
				.required(t('responseMessage.error.yup.name.required')),
			email: Yup.string()
				.email(t('responseMessage.error.yup.email.mail'))
				.required(t('responseMessage.error.yup.email.required')),
			telephone: Yup.string()
				.min(7, t('responseMessage.error.yup.telephone.min'))
				.required(t('responseMessage.error.yup.telephone.required')),
			address: Yup.string().min(7, t('responseMessage.error.yup.address.min')),
			subject: Yup.string(),
			message: Yup.string().required(
				t('responseMessage.error.yup.message.required')
			)
		});
	}
})(FormContact);
export default FormFormik;
