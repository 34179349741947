import React from 'react';
import classes from './ServicesLayout.module.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ServicesLayout = () => {
	const [t, i18n] = useTranslation();

	return (
		<section className={classes.services}>
			<div className={classes.servicesPageInfo}>
				<h1>{t('nav.service')}</h1>
				<p>{t('home.service.description')}</p>
			</div>
			<article className={classes.servicesCollection}>
				<figure>
					<div className={classes.serviceImg}>
						<img
							src={require('../../_assets/Icons/feedSolutions.svg')}
							alt='feed solution img'
						/>
					</div>
					<figcaption>
						<h3>{t('service.service1.name')}</h3>
						<p>{t('home.service.service1')}</p>
						<NavLink className={classes.greenBtn} to='/services/feedSolutions'>
							{t('global.more')}
						</NavLink>
					</figcaption>
				</figure>
				<figure>
					<div className={classes.serviceImg}>
						<img
							src={require('../../_assets/Icons/FeedManufacturingSolution.svg')}
							alt='feed solution img'
						/>
					</div>
					<figcaption>
						<h3>{t('service.service2.name')}</h3>
						<p>{t('home.service.service2')}</p>
						<NavLink
							className={classes.greenBtn}
							to='/services/feedManufactSolutions'
						>
							{t('global.more')}
						</NavLink>
					</figcaption>
				</figure>
				<figure>
					<div className={classes.serviceImg}>
						<img
							src={require('../../_assets/Icons/PoultryFarmSolutions.svg')}
							alt='feed solution img'
						/>
					</div>
					<figcaption>
						<h3>{t('service.service3.name')}</h3>
						<p>{t('home.service.service3')}</p>
						<NavLink
							className={classes.greenBtn}
							to='/services/poulFarmSolutions'
						>
							{t('global.more')}
						</NavLink>
					</figcaption>
				</figure>
				<figure>
					<div className={classes.serviceImg}>
						<img
							src={require('../../_assets/Icons/RenewableGreenEnergy.svg')}
							alt='feed solution img'
						/>
					</div>
					<figcaption>
						<h3>{t('service.service4.name')}</h3>
						<p>{t('home.service.service4')}</p>
						<NavLink
							className={classes.greenBtn}
							to='/services/renewGreenEnergy'
						>
							{t('global.more')}
						</NavLink>
					</figcaption>
				</figure>
			</article>
		</section>
	);
};

export default ServicesLayout;
