import React from 'react';
import classes from './Products.module.scss';
import ProductsLayout from '../Home/ProductsLayout/ProductsLayout'
const Products = (props) => {
	return (
		<main className={classes.products}>
			<ProductsLayout />
		</main>
	);
};

export default Products;


// {/* <section>
// 				<article className={classes.pageInfo}>
// 					<h1>What we offer</h1>
// 					<p>
// 					DNSH expertise provides great nutrition solutions as well as perfect feed formulations in the animal feed business. Our products Components maintain nutrition conditions including; vitamins, minerals, essential amino acids, enzymes, nutritive feed additives and mycotoxin. We believe that having excellent formulating feed is essential for appropriate production and profitable farm.
// 					</p>
// 				</article>
// 				<article className={classes.productsFig}>
// 					<figure>
// 						<div>
// 							<img src={require('../_assets/imgs/Primex.jpeg')} alt="animal health" />
// 						</div>
// 						<figcaption>
// 							<h3>PREMIXES</h3>
// 							<p> e suchen. Kommen Sie noch heute bei DNSH-Trading GmbH vorbei, um mehr zu erfahren.</p>
// 							<NavLink className={classes.greenBtn} to="/products/animalHealth">
// 								More
// 							</NavLink>
// 						</figcaption>
// 					</figure>
// 					<figure>
// 						<div>
// 							<img src={require('../_assets/imgs/Animalshealthbio.jpeg')} alt="animal health" />
// 						</div>
// 						<figcaption>
// 							<h3>PREMIXES</h3>
// 							<p>
// 								Diese Produkte sind unsere bekanntesten und zur Zeit in unserem Geschäft erhältlich.
// 								Dank unserer umfassenden Auswahl können Sie sicher sein, das zu finden, was Sie suchen.
// 								Kommen Sie noch heute bei DNSH-Trading GmbH vorbei, um mehr zu erfahren.
// 							</p>
// 							<NavLink className={classes.greenBtn} to="/products/feedAdditives">
// 								More
// 							</NavLink>
// 						</figcaption>
// 					</figure>
// 					<figure>
// 						<div>
// 							<img src={require('../_assets/imgs/Feedadditives2.jpeg')} alt="animal health" />
// 						</div>
// 						<figcaption>
// 							<h3>PREMIXES</h3>
// 							<p>
// 								{' '}
// 								Geschäft erhältlich. Dank unserer umfassenden Auswahl können Sie sicher sein, das zu
// 								finden, was Sie suchen. Kommen Sie noch heute bei DNSH-Trading GmbH vorbei, um mehr zu
// 								erfahren.
// 							</p>
// 							<NavLink className={classes.greenBtn} to="/products/premixes">
// 								More
// 							</NavLink>
// 						</figcaption>
// 					</figure>
// 				</article>
// 			</section> */}