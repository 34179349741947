import React from 'react';
import ProSerLayout from '../../ProSerLayout/ProSerLayout';
import { useTranslation } from 'react-i18next';

const ServicePage = props => {
	const [t, i18n] = useTranslation();
	let page = props.match.params.serviceName;
	const data = t('productsService.' + page, { returnObjects: true });

	return (
		<>
			<ProSerLayout data={data} />
		</>
	);
};

export default ServicePage;
