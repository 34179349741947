import React from 'react';
import classes from './PartnerLayout.module.scss';
import { useTranslation } from 'react-i18next';

const PartnerLayout = () => {
	const [t, i18n] = useTranslation();

	return (
		<section className={classes.partnerLayout}>
			<h1>{t('nav.partner')}</h1>
			<article>
				<figure>
					<a
						href='http://Str-turkey.net'
						target='_blank'
						rel='noopener noreferrer'
					>
						<img
							src={require('../../_assets/imgs/STElogo.jpg')}
							alt='partner logo'
						/>
					</a>
				</figure>
				<figure>
					<a
						href='http://Tekno-yem.com'
						target='_blank'
						rel='noopener noreferrer'
					>
						<img
							src={require('../../_assets/imgs/tekno.jpg')}
							alt='partner logo'
						/>
					</a>
				</figure>
				<figure>
					<a
						href='http://exafan.com/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<img
							src={require('../../_assets/imgs/exafan.jpg')}
							alt='partner logo'
						/>
					</a>
				</figure>
			</article>
		</section>
	);
};

export default PartnerLayout;
