import React from 'react';
import classes from './Home.module.scss';
import ServiceLayout from './ServicesLayout/ServicesLayout';
import ProductsLayout from './ProductsLayout/ProductsLayout';
import PartnerLayout from './PartnerLayout/PartnerLayout';
import video from '../_assets/imgs/backgroundVideo.mp4';
import { useTranslation } from 'react-i18next';
const Home = props => {
	const [t, i18n] = useTranslation();
	return (
		<main className={classes.home}>
			<section className={classes.HeadImg}>
				<video loop muted autoPlay>
					<source src={video} type='video/mp4' />
					{t('global.videoSupport')}
				</video>
			</section>
			<section className={classes.productsLayout}>
				<hr />

				<ProductsLayout />
			</section>
			<section className={classes.servicesContainer}>
				<ServiceLayout />
			</section>
			<PartnerLayout />
		</main>
	);
};

export default Home;
