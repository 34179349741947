import React from 'react';
import classes from './ProSerLayout.module.scss';
import { useTranslation } from 'react-i18next';

const ProSerLayout = props => {
	const [t, i18n] = useTranslation();
	const dataPrint = props.data.props.map((item, index) => {
		let result = null;
		if (item.type === 'p') {
			result = <p key={index}>{t(props.data.path + '.' + item.key)}</p>;
		} else if (item.type === 'list') {
			let list = [];
			for (let i = 1; i <= item.length; i++) {
				list.push(<li key={i}>{t(props.data.path + '.' + item.key + i)}</li>);
			}
			result = (
				<ul
					key={index}
					style={{
						listStyle: 'disc',
						padding: '1rem'
					}}
				>
					{list}
				</ul>
			);
		}
		return result;
	});
	return (
		<section className={classes.serPruPage}>
			<figure
				style={{
					backgroundImage: `url(${require('../_assets/imgs/' +
						props.data.img)})`
				}}
			>
				<figcaption>
					<div>
						<h2>{props.title}</h2>
						{dataPrint}
						{props.children}
					</div>
				</figcaption>
			</figure>
		</section>
	);
};

export default ProSerLayout;
