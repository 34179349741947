import React from 'react';
import classes from './NavBar.module.scss';
import withSizes from 'react-sizes';
import DesNav from './DesNav/DesNav';
import MobNav from './MobNav/MobNav';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavBar = props => {
	const [t, i18n] = useTranslation();
	const changeLanguageHandler = lang => {
		i18n.changeLanguage(lang);
		localStorage.setItem('langauge', lang);
	};

	const flagStyle = {
		maxWidth: '45px',
		width: '6%',
		minWidth: '40px',
		cursor: 'pointer'
	};
	let displayNav = <MobNav />;
	let buttomNav = null;
	if (!props.isMobile) {
		displayNav = <DesNav />;
		buttomNav = (
			<>
				<hr />
				<ul className={classes.botNav}>
					<li>
						<a href={'mailto:' + t('global.mail')}>{t('global.mail')}</a>
					</li>
					<li>
						<a
							href='https://www.facebook.com/DNSHtrading/'
							rel='noopener noreferrer'
							target='_blank'
						>
							<img
								src={require('../_assets/Icons/facebook.svg')}
								alt='facebook'
							/>
						</a>
					</li>
					<li>
						<a
							href='https://twitter.com/DnshGmbh'
							rel='noopener noreferrer'
							target='_blank'
						>
							<img src={require('../_assets/Icons/twit.svg')} alt='tiwiter' />
						</a>
					</li>
					<li>
						<a href='/'>
							<img
								src={require('../_assets/Icons/linkedin.svg')}
								alt='linkedin'
							/>
						</a>
					</li>
					<li style={flagStyle}>
						<img
							src={require('../_assets/Icons/english.png')}
							alt='english'
							onClick={() => changeLanguageHandler('en')}
						/>
					</li>
					<li style={flagStyle}>
						<img
							src={require('../_assets/Icons/germany.png')}
							alt='german'
							onClick={() => changeLanguageHandler('de')}
						/>
					</li>
					<li style={flagStyle}>
						<img
							src={require('../_assets/Icons/arabic.png')}
							alt='arabic'
							onClick={() => changeLanguageHandler('ar')}
						/>
					</li>
				</ul>
			</>
		);
	}
	return (
		<div className={classes.background}>
			<nav className={classes.navBar}>
				<figure className={classes.logoImg}>
					<div>
						<Link to='/'>
							<img src={require('../_assets/imgs/Logo.png')} alt='' />
						</Link>
					</div>
					<figcaption>
						<h2>{t('global.companyNameGmbh')}</h2>
					</figcaption>
				</figure>
				{displayNav}
			</nav>
			{buttomNav}
		</div>
	);
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 850
});
export default withSizes(mapSizesToProps)(NavBar);
